@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@200;300;400;500;600;700;800&family=Lato:wght@100;300;400;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    overflow-x: hidden;
    /* font-family: 'Lato', sans-serif; */
}
html {
    scroll-behavior: smooth;
}
.open-sans {
    font-family: 'Open Sans', sans-serif;
}
.lato {
    font-family: 'Lato', sans-serif;
}

.roboto {
    font-family: 'Roboto', sans-serif;
}

.montser {
    font-family: 'Montserrat', sans-serif;
}
.dosis {
    font-family: 'Dosis', sans-serif;

}